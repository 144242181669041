import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import ContactTypeDummyRepository
  from './infrastructure/repository/contact-type-dummy-repository/contact-type-dummy-repository';
import ContactTypeHttpRepository
  from './infrastructure/repository/contact-type-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import GetAllContactTypesQuery from './application/queries/get-all-contact-types-query';

// Domain
import { ContactTypeRepository } from './domain/repositories/contact-type-repository';
import { StateManager } from './domain/state/state-manager';

export default function setupContactTypes(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.CONTACT_TYPES_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ContactTypeRepository>(TYPES.CONTACT_TYPES_REPOSITORY)
    .to(
      with_dummies
        ? ContactTypeDummyRepository
        : ContactTypeHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAllContactTypesQuery>(TYPES.GET_ALL_CONTACT_TYPES_QUERY)
    .to(GetAllContactTypesQuery)
    .inSingletonScope();
}
