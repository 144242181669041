


































































































































import { Component, Vue } from 'vue-property-decorator';
import Card from '../Card.vue';
import ProfessionalAndPersonalContextInfoViewModel
  from '@/vue-app/view-models/components/on-boarding/personal-info/professional-and-personal-context-info-view-model';

@Component({
  name: 'ProfessionalAndPersonalContextInfo',
  components: { Card },
})
export default class ProfessionalAndPersonalContextInfo extends Vue {
   professional_context_view_model = Vue
     .observable(new ProfessionalAndPersonalContextInfoViewModel());

   async mounted() {
     await this.professional_context_view_model.initialize();
   }
}
