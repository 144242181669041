import fixtures from './fixtures.json';

// Domain
import {
  YearsKnowingColumbusRepository,
} from '../../../domain/repositories/years-knowing-columbus-repository';
import {
  YearsKnowingColumbusEntity,
} from '../../../domain/entities/years-knowing-columbus-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class YearsKnowingColumbusDummyRepository implements YearsKnowingColumbusRepository {
  get(): Promise<Array<YearsKnowingColumbusEntity>> {
    return Promise.resolve(fixtures);
  }
}
