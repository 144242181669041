import SUBURB_TYPES from './suburb/types';
import NATIONALITY_TYPES from './nationality/types';
import SOURCE_OF_RESOURCES_TYPES from './source-of-resources/types';
import APPROXIMATE_VALUE_TYPES from './approximate-value/types';
import ECONOMIC_ACTIVITY_TYPES from './economic-activity/types';
import PROFESSION_TYPES from './profession/types';
import FINANCIAL_INSTITUTION_TYPES from './financial-institution/types';
import COUNTRY_TYPES from './country/types';
import GENDER_TYPES from './gender/types';
import RELATIONSHIP_TYPES from './relationship/types';
import RESIDENCE_TYPES from './residence/types';
import PROMOTOR_TYPES from './promotor/types';
import MARITAL_STATUS_TYPES from './marital-status/types';
import CURRENCY_TYPES from './currency/types';
import PRODUCT_TYPES from './product/types';
import CONTACT_TYPE_TYPES from './contact-type/types';
import REFERRER_TYPE_TYPES from './referrer-type/types';
import YEARS_KNOWING_COLUMBUS_TYPES from './years-knowing-columbus/types';

export default {
  ...SUBURB_TYPES,
  ...NATIONALITY_TYPES,
  ...SOURCE_OF_RESOURCES_TYPES,
  ...APPROXIMATE_VALUE_TYPES,
  ...ECONOMIC_ACTIVITY_TYPES,
  ...PROFESSION_TYPES,
  ...FINANCIAL_INSTITUTION_TYPES,
  ...COUNTRY_TYPES,
  ...GENDER_TYPES,
  ...RELATIONSHIP_TYPES,
  ...RESIDENCE_TYPES,
  ...PROMOTOR_TYPES,
  ...MARITAL_STATUS_TYPES,
  ...CURRENCY_TYPES,
  ...PRODUCT_TYPES,
  ...CONTACT_TYPE_TYPES,
  ...REFERRER_TYPE_TYPES,
  ...YEARS_KNOWING_COLUMBUS_TYPES,
};
