import fixtures from './fixtures.json';

// Domain
import {
  ContactTypeRepository,
} from '../../../domain/repositories/contact-type-repository';
import {
  ContactTypeEntity,
} from '../../../domain/entities/contact-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ContactTypeDummyRepository implements ContactTypeRepository {
  get(): Promise<Array<ContactTypeEntity>> {
    return Promise.resolve(fixtures);
  }
}
