











































































































import { Component, Vue } from 'vue-property-decorator';

import AddressFormViewModel from '@/vue-app/view-models/components/on-boarding/personal-info/address-form-view-model';
import Card from '../Card.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';

@Component({
  name: 'AddressForm',
  components: {
    Card,
    CustomAutocomplete,
  },
})
export default class AddressForm extends Vue {
  address_form_view_model = Vue.observable(new AddressFormViewModel());

  async created() {
    await this.address_form_view_model.initialize();
  }
}
