import TYPES from '../../types';

// Domain
import {
  ContactTypeEntity,
} from '../../domain/entities/contact-type-entity';
import { ContactTypeRepository } from '../../domain/repositories/contact-type-repository';
import { StateManager } from '../../domain/state/state-manager';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetAllContactTypesQuery extends Query<Promise<Array<ContactTypeEntity>>> {
  @Inject(TYPES.CONTACT_TYPES_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CONTACT_TYPES_REPOSITORY)
  private readonly repository!: ContactTypeRepository;

  internalExecute(): Promise<Array<ContactTypeEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
