import { requiredRule, minLengthRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import GetAllContactTypesQuery
  from '@/modules/onboarding/catalogs/contact-type/application/queries/get-all-contact-types-query';
import GetAllReferrerTypesQuery
  from '@/modules/onboarding/catalogs/referrer-type/application/queries/get-all-referrer-types-query';
import GetAllYearsKnowingColumbusQuery
  from '@/modules/onboarding/catalogs/years-knowing-columbus/application/queries/get-all-years-knowing-columbus-query';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import { ContactTypeEntity }
  from '@/modules/onboarding/catalogs/contact-type/domain/entities/contact-type-entity';
import { ReferrerTypeEntity }
  from '@/modules/onboarding/catalogs/referrer-type/domain/entities/referrer-type-entity';
import { YearsKnowingColumbusEntity }
  from '@/modules/onboarding/catalogs/years-knowing-columbus/domain/entities/years-knowing-columbus-entity';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ProfessionalAndPersonalContextInfoViewModel {
  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.GET_ALL_CONTACT_TYPES_QUERY)
  private readonly get_all_contact_types_query!: GetAllContactTypesQuery;

  @Inject(TYPES.GET_ALL_REFERRER_TYPES_QUERY)
  private readonly get_all_referrer_types_query!: GetAllReferrerTypesQuery;

  @Inject(TYPES.GET_ALL_YEARS_KNOWING_COLUMBUS_QUERY)
  private readonly get_all_years_knowing_columbus_query!: GetAllYearsKnowingColumbusQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  rules = {
    required: [requiredRule, (value: string) => minLengthRule(5, value)],
    select_required_value: [(value: ContactTypeEntity) => value.id !== ''],
  };

  current_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  contact_types: Array<ContactTypeEntity> = []

  selected_contact_type: ContactTypeEntity = {
    id: '',
    name: '',
    label: '',
    description: '',
  }

  referrer_types: Array<ReferrerTypeEntity> = []

  selected_referrer_type: ReferrerTypeEntity = {
    id: '',
    name: '',
    label: '',
    description: '',
  }

  year_knowing_columbus_options: Array<YearsKnowingColumbusEntity> = []

  selected_year_knowing_columbus_option: YearsKnowingColumbusEntity = {
    id: '',
    name: '',
    label: '',
    description: '',
  }

  level_of_education = '';

  institution_where_he_completed_his_studies = '';

  professional_development = '';

  initialize = async () => {
    await this.loadCurrentStepInformation();
    await this.loadContactTypes();
    await this.loadReferrerTypes();
    await this.loadYearsKnowingColumbus();
    this.setInitialData();
  }

  loadCurrentStepInformation = async () => {
    try {
      this.current_step = await this.get_on_boarding_step_query.execute('personal_information');
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar la información de la página');
    }
  }

  loadContactTypes = async () => {
    try {
      this.contact_types = await this.get_all_contact_types_query.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar los tipos de contacto');
    }
  }

  loadReferrerTypes = async () => {
    try {
      this.referrer_types = await this.get_all_referrer_types_query.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar los tipos de referidor');
    }
  }

  loadYearsKnowingColumbus = async () => {
    try {
      this.year_knowing_columbus_options = await this
        .get_all_years_knowing_columbus_query.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar los años de conocer a Columbus');
    }
  }

  setInitialData = () => {
    this.level_of_education = this.current_step.payload.level_of_education;
    this.institution_where_he_completed_his_studies = this.current_step
      .payload.institution_where_he_completed_his_studies;
    this.professional_development = this.current_step.payload.professional_development;
    this.selected_contact_type = { ...this.current_step.payload.how_the_contact_was_made };
    this.selected_referrer_type = { ...this.current_step.payload.who_referred_him };
    this.selected_year_knowing_columbus_option = {
      ...this.current_step.payload.since_when_has_he_known_us,
    };
  }
}
