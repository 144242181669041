import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import YearsKnowingColumbusDummyRepository
  from './infrastructure/repository/years-knowing-columbus-dummy-repository/years-knowing-columbus-dummy-repository';
import YearsKnowingColumbusHttpRepository
  from './infrastructure/repository/years-knowing-columbus-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import GetAllYearsKnowingColumbusQuery from './application/queries/get-all-years-knowing-columbus-query';

// Domain
import { YearsKnowingColumbusRepository } from './domain/repositories/years-knowing-columbus-repository';
import { StateManager } from './domain/state/state-manager';

export default function setupYearsKnowingColumbus(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.YEARS_KNOWING_COLUMBUS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<YearsKnowingColumbusRepository>(TYPES.YEARS_KNOWING_COLUMBUS_REPOSITORY)
    .to(
      with_dummies
        ? YearsKnowingColumbusDummyRepository
        : YearsKnowingColumbusHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAllYearsKnowingColumbusQuery>(TYPES.GET_ALL_YEARS_KNOWING_COLUMBUS_QUERY)
    .to(GetAllYearsKnowingColumbusQuery)
    .inSingletonScope();
}
