import { v4 } from 'uuid';

import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import { GetSuburbsInZipCodeQuery } from '@/modules/onboarding/catalogs/suburb/application/queries';

// Domain
import { CustomerAddressEntity }
  from '@/modules/onboarding/address/domain/entities/customer-address-entity';
import { SuburbInZipCodeEntity } from '@/modules/onboarding/catalogs/suburb/domain/entities';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AddressFormViewModel {
  @Inject(TYPES.GET_SUBURBS_IN_ZIP_CODE_QUERY)
  readonly get_suburbs_in_zip_code_query!: GetSuburbsInZipCodeQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  current_step: StepEntity = {
    id: '',
    current_step: '',
    payload: {},
  };

  personal_information_step: StepEntity = {
    id: '',
    current_step: '',
    payload: {},
  };

  rules = {
    required: [requiredRule],
  };

  suburbs_in_zip_code: Array<SuburbInZipCodeEntity> = []

  inputs: CustomerAddressEntity = {
    customer_address_proof_of_address_id: null,
    customer_address_id: '',
    customer_address_default: false,
    address: {
      id: '',
      street: '',
      external_number: '',
      interior_number: '',
      zip_code: '',
      settlement: '',
      municipality: '',
      state: '',
      city: '',
      address_type: 'fiscal',
      country: {
        id: '',
        name: '',
      },
      created_at: new Date().toUTCString(),
      updated_at: new Date().toUTCString(),
    },
  };

  input_city_is_disabled = false;

  loadsSuburbsInZipCode = async (is_new_zip_code: boolean) => {
    try {
      if (this.inputs.address.zip_code.length === 5) {
        this.suburbs_in_zip_code = await this.get_suburbs_in_zip_code_query
          .execute(this.inputs.address.zip_code);

        if (is_new_zip_code && this.suburbs_in_zip_code.length) {
          this.inputs.address.municipality = this.suburbs_in_zip_code[0].municipality_name;
          this.inputs.address.state = this.suburbs_in_zip_code[0].state_name;
          this.inputs.address.city = this.suburbs_in_zip_code[0].city_name;
          this.inputs.address.settlement = '';
          this.input_city_is_disabled = !!this.inputs.address.city;
        } else if (is_new_zip_code && !this.suburbs_in_zip_code.length) {
          this.inputs.address.municipality = '';
          this.inputs.address.state = '';
          this.inputs.address.settlement = '';
          this.inputs.address.city = '';
          this.input_city_is_disabled = false;
        }
      }
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar las colonias');
    }
  }

  setCustomerAddressCountry = async () => {
    if (!this.inputs.address.country.id) {
      await this.loadPersonalInformationStep();
      const address = this.personal_information_step.payload.addresses.find(
        (item: CustomerAddressEntity) => item.address.address_type === 'personal',
      );
      if (address) {
        this.inputs.address.country = {
          id: address.address.country.id,
          name: address.address.country.name,
        };
      }
    }
  }

  initialize = async () => {
    this.current_step = await this.get_on_boarding_step_query.execute('tax_information');
    await this.setCustomerAddressOrInitializeIt();
    await this.setCustomerAddressCountry();
  }

  private async setCustomerAddressOrInitializeIt() {
    const customer_address = this.current_step.payload.addresses.find(
      (item: CustomerAddressEntity) => item.address.address_type === 'fiscal',
    );

    if (customer_address) {
      this.inputs = customer_address;
      await this.loadsSuburbsInZipCode(false);
    } else {
      const id = v4();
      this.inputs.customer_address_id = id;
      this.inputs.address.id = id;
    }
  }

  loadPersonalInformationStep = async () => {
    try {
      this.personal_information_step = await this.get_on_boarding_step_query.execute('personal_information');
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar su información personal');
    }
  }
}
