import TYPES from '../../types';

// Domain
import {
  YearsKnowingColumbusEntity,
} from '../../domain/entities/years-knowing-columbus-entity';
import { YearsKnowingColumbusRepository } from '../../domain/repositories/years-knowing-columbus-repository';
import { StateManager } from '../../domain/state/state-manager';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetAllYearsKnowingColumbusQuery extends
  Query<Promise<Array<YearsKnowingColumbusEntity>>> {
  @Inject(TYPES.YEARS_KNOWING_COLUMBUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.YEARS_KNOWING_COLUMBUS_REPOSITORY)
  private readonly repository!: YearsKnowingColumbusRepository;

  internalExecute(): Promise<Array<YearsKnowingColumbusEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
