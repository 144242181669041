import { Container } from 'inversify-props';
import setupSuburb from './suburb/setup';
import setupNationality from './nationality/setup';
import setupSourceOfResources from './source-of-resources/setup';
import setupApproximateValue from './approximate-value/setup';
import setupEconomicActivity from './economic-activity/setup';
import setupProfession from './profession/setup';
import setupFinancialInstitution from './financial-institution/setup';
import setupCountry from './country/setup';
import setupGender from './gender/setup';
import setupResidence from './residence/setup';
import setupState from './state/setup';
import setupRelationship from './relationship/setup';
import setupPromotorModule from '@/modules/onboarding/catalogs/promotor/setup';
import setupMaritalStatusModule from '@/modules/onboarding/catalogs/marital-status/setup';
import setupCurrency from './currency/setup';
import setupProduct from './product/setup';
import setupContactTypes from './contact-type/setup';
import setupReferrerTypes from './referrer-type/setup';
import setupYearsKnowingColumbus from './years-knowing-columbus/setup';

export default function setupCatalogs(container: Container) {
  setupSuburb(container);
  setupNationality(container);
  setupSourceOfResources(container);
  setupApproximateValue(container);
  setupEconomicActivity(container);
  setupProfession(container);
  setupFinancialInstitution(container);
  setupCountry(container);
  setupGender(container);
  setupResidence(container);
  setupState(container);
  setupRelationship(container);
  setupPromotorModule(container);
  setupMaritalStatusModule(container);
  setupCurrency(container);
  setupProduct(container);
  setupContactTypes(container);
  setupReferrerTypes(container);
  setupYearsKnowingColumbus(container);
}
