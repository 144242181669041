import TYPES from '@/modules/shared/types';

// Domain
import {
  YearsKnowingColumbusRepository,
} from '../../domain/repositories/years-knowing-columbus-repository';
import {
  YearsKnowingColumbusEntity,
} from '../../domain/entities/years-knowing-columbus-entity';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class YearsKnowingColumbusHttpRepository implements YearsKnowingColumbusRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/catalogs/years-knowing-columbus/`;

  get(): Promise<Array<YearsKnowingColumbusEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => response.json())
        .then(({ success, message, data }) => {
          if (success) {
            resolve(data);
          } else {
            reject(new Error(message));
          }
        })
        .catch(reject);
    });
  }
}
