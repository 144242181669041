import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import ReferrerTypeDummyRepository
  from './infrastructure/repository/referrer-type-dummy-repository/referrer-type-dummy-repository';
import ReferrerTypeHttpRepository
  from './infrastructure/repository/referrer-type-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import GetAllReferrerTypesQuery from './application/queries/get-all-referrer-types-query';

// Domain
import { ReferrerTypeRepository } from './domain/repositories/referrer-type-repository';
import { StateManager } from './domain/state/state-manager';

export default function setupReferrerTypes(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.REFERRER_TYPES_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ReferrerTypeRepository>(TYPES.REFERRER_TYPES_REPOSITORY)
    .to(
      with_dummies
        ? ReferrerTypeDummyRepository
        : ReferrerTypeHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAllReferrerTypesQuery>(TYPES.GET_ALL_REFERRER_TYPES_QUERY)
    .to(GetAllReferrerTypesQuery)
    .inSingletonScope();
}
