import fixtures from './fixtures.json';

// Domain
import {
  ReferrerTypeRepository,
} from '../../../domain/repositories/referrer-type-repository';
import {
  ReferrerTypeEntity,
} from '../../../domain/entities/referrer-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ReferrerTypeDummyRepository implements ReferrerTypeRepository {
  get(): Promise<Array<ReferrerTypeEntity>> {
    return Promise.resolve(fixtures);
  }
}
