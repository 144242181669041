import TYPES from '@/types';
import { v4 } from 'uuid';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import SendBankAccountCommandService from '@/modules/onboarding/documents/application/commands/send-bank-account-command-service';
import SendProofOfAddressCommandService from '@/modules/onboarding/documents/application/commands/send-proof-of-address-command-service';
import CreateOnboardingStepCommand from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import SearchDocumentsQuery from '@/modules/onboarding/documents/application/queries/search-documents-query';
import GetInternetStatusQuery from '@/modules/internet-status/application/queries/get-internet-status-query';
import Base64DataUriToFileConverter from '@/modules/onboarding/file/application/services/base64-data-uri-to-file-converter';
import { UseIneAsProofOfAddressCommand } from '@/modules/onboarding/proof-of-address/application/commands';
import GetCustomerBase64DocumentQuery from '@/modules/onboarding/customer-document/application/queries/get-customer-base64-document-query';
import MimeTypeFromBase64 from '@/modules/onboarding/file/application/services/mime-type-from-base64';

// Domain
import { DocumentEntity } from '@/modules/onboarding/documents/domain/entities/document-entity';
import { CreateDocumentDto } from '@/modules/onboarding/documents/domain/dtos/create-document-dto';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

export class UploadDocumentsPageViewModel {
  @Inject(TYPES.SEND_BANK_ACCOUNT_COMMAND_SERVICE)
  readonly sendBankAccountCommand!: SendBankAccountCommandService;

  @Inject(TYPES.SEND_PROOF_OF_ADDRESS_COMMAND_SERVICE)
  readonly sendProofOfAddressCommandService!: SendProofOfAddressCommandService;

  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.SEARCH_DOCUMENTS_QUERY)
  readonly searchDocumentsQuery!: SearchDocumentsQuery;

  @Inject(TYPES.USE_INE_AS_PROOF_OF_ADDRESS_COMMAND)
  readonly use_ine_as_proof_of_address_command!: UseIneAsProofOfAddressCommand;

  @Inject(TYPES.GET_CUSTOMER_BASE64_DOCUMENT_QUERY)
  get_customer_base64_document_query!: GetCustomerBase64DocumentQuery;

  @Inject(TYPES.NOTIFIER)
  readonly notifier!: MessageNotifier;

  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  readonly getInternetStatusQuery!: GetInternetStatusQuery;

  use_ine_as_proof_of_address = false;

  modals = {
    accepted_proof_of_address: {
      is_open: false,
    },
  };

  step_ids = {
    personal_information: v4(),
  };

  files: Record<string, null | File> = {
    bank_statement: null,
    proof_of_address: null,
  };

  bank_statement_accept = 'image/*';

  proof_of_address_accept = 'image/*,.pdf';

  limit_file_size = 4 * 1024 * 1024;

  loading = {
    bank_statement: false,
    proof_of_address: false,
  };

  loading_previews = false;

  previews = {
    bank_statement: null as null | File,
    proof_of_address: null as null | File,
  };

  valid_form = 'valid';

  rules = {
    files: [requiredRule],
  }

  proof_of_address_request_id!: string;

  customer_documents!: Array<DocumentEntity>;

  get internetStatus() {
    return this.getInternetStatusQuery.execute();
  }

  get can_continue() {
    return (
      !Object.values(this.loading).some((value) => value)
    );
  }

  constructor() {
    this.proof_of_address_request_id = v4();
  }

  openAcceptedProofOfAddress = () => {
    this.modals.accepted_proof_of_address.is_open = true;
  };

  selectBankStatement = async (base64: string, mime_type: string, file_name: string) => {
    try {
      this.loading.bank_statement = true;
      const bank_account_entity: CreateDocumentDto = {
        id: v4(),
        name: file_name,
        mime_type,
        file_data: base64,
      };
      await this.sendBankAccountCommand.execute(bank_account_entity);
      const bank_statement_file = await Base64DataUriToFileConverter.convert(
        base64,
        mime_type,
        bank_account_entity.name,
      );
      this.previews.bank_statement = bank_statement_file;
      this.files.bank_statement = bank_statement_file;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.loading.bank_statement = false;
    }
  };

  selectProofOfAddress = async (base64: string, mime_type: string, file_name: string) => {
    try {
      this.loading.proof_of_address = true;
      const proof_of_address: CreateDocumentDto = {
        id: this.proof_of_address_request_id,
        name: file_name,
        mime_type,
        file_data: base64,
      };
      await this.sendProofOfAddressCommandService.execute(proof_of_address);
      const proof_of_address_file = await Base64DataUriToFileConverter.convert(
        base64,
        mime_type,
        proof_of_address.name,
      );
      this.previews.proof_of_address = proof_of_address_file;
      this.files.proof_of_address = proof_of_address_file;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.loading.proof_of_address = false;
    }
  };

  useIneAsProofOfAddress = async () => {
    if (this.use_ine_as_proof_of_address) {
      try {
        this.loading.proof_of_address = true;
        await this.use_ine_as_proof_of_address_command.execute(this.proof_of_address_request_id);
      } catch {
        this.notifier.showErrorNotification('Ocurrió un error al usar su INE como comprobante de domicilio');
      } finally {
        this.loading.proof_of_address = false;
      }
    }
  };

  updateStepData = async () => {
    await this.createOnboardingStepCommand.execute({
      id: this.step_ids.personal_information,
      current_step: 'personal_information',
      payload: {
        confirm_data: {
          personal: false,
          address: false,
        },
        personal_information: {
          allow_whatsapp_contact: null,
        },
        addresses: [],
        phones: [],
        cellphones: [],
        agreements: [],
        marital_status_id: null,
        how_the_contact_was_made: {
          id: '',
          name: '',
          label: '',
          description: '',
        },
        since_when_has_he_known_us: {
          id: '',
          name: '',
          label: '',
          description: '',
        },
        who_referred_him: {
          id: '',
          name: '',
          label: '',
          description: '',
        },
        level_of_education: '',
        institution_where_he_completed_his_studies: '',
        professional_development: '',
      },
    });
  };

  setInitialDocumentsData = async () => {
    await this.getAllCustomerDocuments();
    const bank_statement = this.customer_documents.find((item) => item.document_type.name === 'bank_statement');
    const proof_of_address = this.customer_documents.find((item) => item.document_type.name === 'proof_of_address');

    if (bank_statement) {
      const file_name = bank_statement.file.name;
      const file_type = file_name.split('.').pop();
      this.previews.bank_statement = await this.downloadCustomerDocumentPreview(
        bank_statement.customer_document_id,
        file_name,
      );
      this.files.bank_statement = new File([], file_name, {
        type: file_type === 'pdf' ? file_type : `image/${file_type}`,
      });
    }

    if (proof_of_address) {
      const file_name = proof_of_address.file.name;
      const file_type = file_name.split('.').pop();
      this.previews.proof_of_address = await this.downloadCustomerDocumentPreview(
        proof_of_address.customer_document_id,
        file_name,
      );
      this.files.proof_of_address = new File([], file_name, {
        type: file_type === 'pdf' ? file_type : `image/${file_type}`,
      });
    }
  };

  getAllCustomerDocuments = async () => {
    try {
      this.customer_documents = await this.searchDocumentsQuery.execute();
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener los documentos del usuario');
    }
  };

  downloadCustomerDocumentPreview = async (customer_document_id: string, file_name: string) => {
    this.loading_previews = true;
    try {
      const base64 = await this.get_customer_base64_document_query.execute(customer_document_id);
      const mime_type = MimeTypeFromBase64.getFileMimeType(base64);
      return Base64DataUriToFileConverter.convert(base64, mime_type, file_name);
    } catch (e) {
      this.notifier.showErrorNotification('Ocurrió un error al descargar la previsualización del documento');
      return null;
    } finally {
      this.loading_previews = false;
    }
  };

  async initialize() {
    await this.setInitialDocumentsData();
  }

  onCanContinueChange = () => {
    if (this.can_continue) {
      this.valid_form = 'valid';
    } else {
      this.valid_form = '';
    }
  };

  onLoadingChange = () => {
    const is_loading = Object
      .values(this.loading)
      .some((value) => value);

    if (is_loading) {
      this.notifier.showLoadingNotification('Estamos cargando su documento, terminaremos en unos segundos');
    } else {
      this.notifier.hideLoadingNotification();
    }
  };
}
